// Here you can add other styles
@import url('https://fonts.googleapis.com/css?family=Roboto');

.col-dense {
    padding-left: 5px;
    padding-right: 5px;
}

label {
    font-weight: bold;
    font-family: Roboto;
}

span {
    font-family: Roboto;   
}

span.output {
    color: #8f9ba6;
}

.heading-span {
    font-weight: bold;
}

.card {
    margin-bottom: 1rem;
}

.card-header {
    padding: 0.3rem .5rem;
    
    h5 {
        margin-bottom: 0;
        font-size: 1rem;
    }
}

.card-body {
    padding: .2rem 1.25rem 0 1.25rem;
}

.form-group {
    label {
        margin-bottom: .2rem;
        font-size: .8rem;
    }
    margin-bottom: .5rem;
}

@media (min-width: 992px) {
    .brand-minimized .app-header .navbar-brand {
        width: 155px;
    }

    .aside-menu {
        width: 300px;
    }

    html:not([dir="rtl"]) .aside-menu {
        margin-right: -300px;
    }

    html:not([dir="rtl"]) .aside-menu-lg-show.aside-menu-fixed .main {
        margin-right: 300px;
    }

    html:not([dir="rtl"]) .aside-menu-lg-show.aside-menu-fixed .app-footer {
        margin-right: 300px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
